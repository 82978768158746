import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";

import router from "./router";
import store from "./store";
import axios from "axios";
import Toaster from "@meforma/vue-toaster";

import "./assets/bootstrap/css/bootstrap.min.css";
import "./assets/css/main.css";
import "./assets/css/tracker.css";
import "./assets/css/animate.min.css";

// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.baseURL = "https://gcmsonline.ca/api/";

createApp(App).use(store).use(Toaster).use(router).mount("#app");

