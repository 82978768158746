<template>
    <footer class="d-flex justify-content-center">
        <div style="width: 80%;">
            <div class="row">
                <div class="col d-flex flex-column justify-content-center align-items-center py-5"><img class="mb-4 logo" src="@/assets/img/gcmsonline-logo.svg">
                    <ul class="nav nav-fill d-flex flex-column flex-lg-row" >
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'home' }">Home</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'order' }">Order</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'track' }">Track</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'faq' }">FAQ</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'contact' }">Contact us</router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-lg-2 text-center text-lg-start align-items-center" style="height: 80px;border-top: 1px solid grey;">
                <div class="col"><small>Copyright © {{ year }} gcmsonline.ca</small></div>
                <div class="col d-lg-flex justify-content-end"><small><router-link :to="{ name: 'privacyPolicy' }">Privacy Policy</router-link><router-link class="mx-3" :to="{ name: 'terms&conditions' }">Terms of Service</router-link><router-link :to="{ name: 'cookieSettings' }">Cookies Settings</router-link></small></div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterLayout",

    data(){
        return{
            year: new Date().getFullYear()
        }
    }
};
</script>
