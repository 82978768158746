<template>
    <app-layout/>
</template>


<script>
import AppLayout from './components/layouts/Layout.vue';
export default {
  name: 'HelloWorld',
  components: {
    AppLayout
  }
}
</script>


