import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PageNotFoundView from "../views/PageNotFoundView.vue";

import store from "../store";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: PageNotFoundView,
  },

  {
    path: "/order",
    name: "order",
    component: () => import("../views/OrderView.vue"),
  },
  {
    path: "/upload/:trackingNumber",
    name: "uploadConsentForm",
    component: () => import("../views/UploadView.vue"),
    props: (route) => ({
      trackingNo: route.params.trackingNumber,
    }),
  },
  {
    path: "/upload/ircc/:trackingNumber",
    name: "uploadConsentFormIRCC",
    component: () => import("../views/UploadViewIRCC.vue"),
    props: (route) => ({
      trackingNo: route.params.trackingNumber,
    }),
  },
  {
    path: "/upload/cbsa/:trackingNumber",
    name: "uploadConsentFormCBSA",
    component: () => import("../views/UploadViewCBSA.vue"),
    props: (route) => ({
      trackingNo: route.params.trackingNumber,
    }),
  },
  {
    path: "/upload/csis/:trackingNumber",
    name: "uploadConsentFormCSIS",
    component: () => import("../views/UploadViewCSIS.vue"),
    props: (route) => ({
      trackingNo: route.params.trackingNumber,
    }),
  },
  {
    path: "/track",
    name: "track",
    component: () => import("../views/TrackView.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("../views/FAQView.vue"),
  },
  {
    path: "/terms&conditions",
    name: "terms&conditions",
    component: () => import("../views/TermsAndConditions.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/cookie-settings",
    name: "cookieSettings",
    component: () => import("../views/CookieSettings.vue"),
  },
  {
    path: "/contact-us",
    name: "contact",
    component: () => import("../views/ContactUsView.vue"),
  },

  {
    path: "/admin",
    name: "login",
    component: () => import("../views/admin/LoginView.vue"),
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/admin/DashboardView.vue"),
    meta: {
      requireLogin: true,
    },
  },

  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/admin/SettingsView.vue"),
    meta: {
      requireLogin: true,
    },
    children: [
      {
        path: "users",
        name: "users",
        component: () => import("../views/admin/settings/UserSettings.vue"),
        meta: {
          requireLogin: true,
        },
      },
      {
        path: "services",
        name: "service",
        component: () => import("../views/admin/settings/ServiceSettings.vue"),
        meta: {
          requireLogin: true,
        },
      },
      {
        path: "orders",
        name: "orders",
        component: () => import("../views/admin/settings/OrderSettings.vue"),
        meta: {
          requireLogin: true,
        },
      },


      {
        path: "create",
        name: "create",
        component: () => import("../views/admin/settings/CreateUser.vue"),
        meta: {
          requireLogin: true,
        },
      },
      {
        path: "update/:id",
        name: "update",
        component: () => import("../views/admin/settings/UpdateUser.vue"),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: {
          requireLogin: true,
        },
      },
      {
        path: "detail/:name/:id",
        name: "detail",
        component: () => import("../views/admin/settings/DetailUser.vue"),
        props: (route) => ({
          id: route.params.id,
          name: route.params.name,
        }),
        meta: {
          requireLogin: true,
        },
      },
    ],
  },

  {
    path: "/detail/:trackingNumber",
    name: "detailPage",
    component: () => import("../views/admin/DetailPageView.vue"),
    props: (route) => ({
      trackingNo: route.params.trackingNumber,
    }),
    meta: {
      requireLogin: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  
  if (
    to.matched.some((record) => record.meta.requireLogin) &&
    !store.state.isAuthenticated
  ) {
    window.scrollTo(0, 0);
    next({ name: "login", query: { to: to.path } });
  } else {
    window.scrollTo(0, 0);
    next();
  }
});

export default router;
