<template>

<section class="py-5 mt-5">
    <div class="container py-5">
        <div class="row">
            <div class="col-md-8 col-xl-6 text-center mx-auto">
                <h2 class="display-6 fw-bold mb-4"><span class="underline">404</span></h2>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col" style="min-height: 40vh;">
                <h1 class="display-1 text-center my-5">Page not Found</h1>
            </div>
        </div>
    </div>
</section>

</template>