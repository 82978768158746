import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    isAuthenticated: false,
    token: "",
    isLoading: false,
    isSuperAdmin: false,
    user: {},
    orders: [],
  },
  getters: {
    orders(state) {
      return state.orders;
    },
    isSuperAdmin(state){
      return state.isSuperAdmin;
    },
    isLoading(state){
      return state.isLoading;
    },
    user(state){
      return state.user;
    },
    isAuthenticated(state){
      return state.isAuthenticated;
    },
    token(state){
      return state.token;
    },
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem("token")) {
        state.token = localStorage.getItem("token");
        state.isAuthenticated = true;
      } else {
        state.token = "";
        state.isAuthenticated = false;
      }
    },
    setIsLoading(state, status) {
      state.isLoading = status;
    },
    setIsSuperAdmin(state, status) {
      state.isSuperAdmin = status;
    },
    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    },
    setUser(state, user) {
      state.user = user;
    },
    setOrders(state, orders) {
      state.orders = orders;
    },
    removeUser(state) {
      state.user = {};
    },
    logout(state) {
      axios.defaults.headers.common["Authorization"] = "";

      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("userid");

      state.token = "";
      state.isAuthenticated = false;

    },
  },
  actions: {},
  modules: {},
});
