<template>
        <nav class="navbar navbar-light navbar-expand-lg fixed-top shadow">
        <div class="container">
            <router-link class="navbar-brand" :to="{ name: 'home' }"><img alt="Website logo"
                    src="@/assets/img/gcmsonline-logo.svg" /></router-link>
            <button @click="collapseMenu = !collapseMenu" data-bs-toggle="collapse" class="navbar-toggler" data-bs-target="#navcol-1"><span class="visually-hidden">Toggle navigation</span><span class="navbar-toggler-icon"></span></button>
            <div v-bind:class="{ collapse: collapseMenu }" class="navbar-collapse"  id="navcol-1">
                <ul class="navbar-nav mx-auto">
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'home' }">Home</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'order' }">Order</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'track' }">Track</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'faq' }">FAQ</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'contact' }">Contact us</router-link>
                    </li>
                    <span v-if="$store.getters.isAuthenticated">
                        <li v-bind:class="{ show: show }" @click="show = !show" class="nav-item dropdown"><a
                                class="dropdown-toggle cursor nav-link" data-bs-toggle="dropdown">Admin</a>
                            <div v-bind:class="{ show: show }" class="dropdown-menu">
                                <router-link class="dropdown-item"
                                    :to="{ name: 'dashboard' }">Dashboard</router-link><router-link v-if="$store.getters.isSuperAdmin"
                                    class="dropdown-item cursor" :to="{ name: 'users' }">Settings</router-link><a
                                    @click="logout()" class="dropdown-item text-danger cursor">Logout</a>
                            </div>
                        </li>
                    </span>
                </ul>
                <router-link class="btn btn-danger rounded-0 px-4 d-none d-lg-block" role="button" :to="{ name: 'order' }">Order Now</router-link>
            </div>
        </div>
    </nav>
</template>

<script>

export default {

    name: "NavbarLayout",

    data() {
        return {
            collapseMenu: true,
            show: false,
        };
    },
    methods: {
        logout() {
            this.$store.commit('logout');
            this.$router.push("/");

        },
        close(e) {
            if (!this.$el.contains(e.target)) {
                this.show = false
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.close)
    },
    beforeUnmount() {
        document.removeEventListener('click', this.close)
    }
};
</script>

<style>
nav .nav-item a.router-link-exact-active {
    color: #FF1414;
}

nav .nav-item a.router-link-exact-active:hover {
    text-decoration: none;
}

</style>