<template>
  <div>
    <NavbarLayout />
    <div style="padding-top: 80px;">
    <router-view />
    </div>
    <FooterLayout />
  </div>
</template>
      

<script>
import axios from 'axios'

import FooterLayout from "./Footer";
import NavbarLayout from "./Navbar";
export default {
  name: "AppLayout",
  components: {
    NavbarLayout,
    FooterLayout,
  },

  beforeCreate() {
    this.$store.commit('initializeStore')

    const token = this.$store.getters.token

    if (token) {
      axios.defaults.headers.common['Authorization'] = "Bearer " + token
    } else {
      axios.defaults.headers.common['Authorization'] = ""
    }
  },
};
</script>


